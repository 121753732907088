import React, { useState } from 'react';
// DEPENDENCIES

// COMPONENTS
import { SolidButton } from '@bit/medzcool.cytoplasm.buttons';
import { grayscale } from '@bit/medzcool.cytoplasm.colors';

// STYLES
import styled from 'styled-components';

// Apollo, GraphQL
import { useMutation } from '@apollo/client';
import { EDIT_THUMBNAIL } from '../DataStructures/Mutations';

export default function EditThumbnail(props) {
  const { encounter } = props;
  const [editThumbnail] = useMutation(EDIT_THUMBNAIL);

  function addImage() {
    let file;
    const imgTypes = /image\//;
    const virtualElement = document.createElement('input');

    virtualElement.setAttribute('type', 'file');
    virtualElement.setAttribute('accept', 'image/*');
    virtualElement.click();

    // user opened (selected) files on the popup----proceed further to handle it / upload
    virtualElement.addEventListener('change', () => {
      file = virtualElement.files[0];
      const fileReader = new FileReader();

      if (file.size > 8388608) {
        console.log('FILE TOO BIG');
        alert('This file is too large');
        return null;
      }

      fileReader.onerror = (e) => {
        console.log(
          `Unable to proceed with the file requested. Error: ${e.detail}`,
        );
      };

      // user submits an image successfully
      fileReader.onload = () => {
        if (imgTypes.test(file.type)) {
          //   console.log('success', fileReader.result);

          editThumbnail({
            variables: {
              encounterId: encounter.id,
              imageData: fileReader.result,
              filename: file.name,
              fileType: file.type,
            },
          });
        } else {
          // can handle some modal/popup/tooltip alerting user of wrong file type
          console.error('The file type requested is not an image type!');
          return false;
        }
      };
      // finally, begin loading the image and fire the event handlers
      fileReader.readAsDataURL(file);
    });
  }

  return (
    <ThumbnailContainer>
      <h3>Thumbnail</h3>
      <p className="descriptor">
        For the best quality, image should be square with a resolution of
        600x600 px
      </p>
      <div className="img-container">
        {encounter.thumbnail && <img src={encounter.thumbnail} />}
      </div>

      <SolidButton onClick={addImage}>
        {encounter.thumbnail ? 'Change Thumbnail' : 'Upload Thumbnail'}
      </SolidButton>
    </ThumbnailContainer>
  );
}
const ThumbnailContainer = styled.div`
  ${SolidButton} {
    margin-bottom: 15px;
    width: max-content;
  }
  h3 {
    margin-bottom: 0;
  }
  .descriptor {
    margin-top: 0;
    margin-bottom: 25px;
    color: ${grayscale.lightDark};
    font-size: 0.75em;
  }
  .img-container {
    img {
      max-width: 500px;
    }
  }
`;
