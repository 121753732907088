import React, { useState } from 'react';
// DEPENDENCIES
// COMPONENTS
// STYLES
import styled from 'styled-components';

// Apollo, GraphQL
import { useMutation } from '@apollo/client';
import { EDIT_FLAG } from '../DataStructures/Mutations';

export default function EditAlarms(props) {
  const { encounter } = props;

  const [incomplete, setIncomplete] = useState(encounter.incomplete);
  const [attention, setAttention] = useState(encounter.attention);
  const [isPublic, setIsPublic] = useState(encounter.isPublic);
  const [clinicEncounter, setClinicEncounter] = useState(
    encounter.clinicEncounter,
  );

  const [editFlag] = useMutation(EDIT_FLAG);

  function handleChange(flag, flagValue, stateSet) {
    stateSet(flagValue);
    editFlag({
      variables: {
        encounterId: encounter.id,
        flagValue: flagValue,
        flag: flag,
      },
    });
  }

  return (
    <FlagContainer>
      <h3>Flags</h3>
      <div className="flag-block">
        <input
          type="checkbox"
          id="incomplete"
          name="incomplete"
          value="incomplete"
          checked={incomplete}
          className="checkbox"
          onChange={() =>
            handleChange(
              'incomplete',
              !incomplete,
              setIncomplete,
            )
          }
        />
        <label className="checkbox-text" htmlFor="incomplete">
          Incomplete
        </label>
      </div>
      <div className="flag-block">
        <input
          type="checkbox"
          id="attention"
          name="attention"
          value="attention"
          checked={attention}
          className="checkbox"
          onChange={() => handleChange('attention', !attention, setAttention)}
        />
        <label className="checkbox-text" htmlFor="attention">
          Requires Attention
        </label>
      </div>
      <div className="flag-block">
        <input
          type="checkbox"
          id="isPublic"
          name="isPublic"
          value="isPublic"
          checked={isPublic}
          className="checkbox"
          onChange={() => handleChange('isPublic', !isPublic, setIsPublic)}
        />
        <label className="checkbox-text" htmlFor="isPublic">
          Public Encounter
        </label>
      </div>
      <div className="flag-block">
        <input
          type="checkbox"
          id="clinicEncounter"
          name="clinicEncounter"
          value="clinicEncounter"
          checked={clinicEncounter}
          className="checkbox"
          onChange={() =>
            handleChange(
              'clinicEncounter',
              !clinicEncounter,
              setClinicEncounter,
            )
          }
        />
        <label className="checkbox-text" htmlFor="clinicEncounter">
          Clinic Encounter
        </label>
      </div>
    </FlagContainer>
  );
}
const FlagContainer = styled.div`
  .flag-block {
    margin-bottom: 25px;
  }
  .checkbox {
    height: 20px;
    width: 20px;
    cursor: pointer;
  }
  .checkbox-text {
    margin-left: 5px;
    cursor: pointer;
  }
`;
