import styled from 'styled-components';

export const Block = styled.div`
  background: #ffffff;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 25px;
  height: fit-content;
  margin-bottom: 15px;
  .document-title {
    color: gray;
    font-size: 0.75em;
    margin-bottom: 15px;
  }
  @media(max-width: 768px) {
    border-radius: 0;
    padding: 18px;
  }
`;
