import React, { useState, useEffect } from 'react';
// DEPENDENCIES
// COMPONENTS
import { Block } from './PreviewComponents/Block/Block';

// STYLES
import styled from 'styled-components';
import { grayscale } from '@bit/medzcool.cytoplasm.colors';

// DATA

export default function EncounterCardMini(props) {
  const { encounter, history } = props;

  const readableDate = new Date(encounter.dateCreated).toDateString();

  function truncate(str, n) {
    return str.length > n ? str.substr(0, n - 1) + '...' : str;
  }

  return (
    <EncounterCardContainer
      onClick={() => history.push(`/edit/${encounter.hashId}`)}
      style={{ cursor: 'pointer' }}
    >
      <Block style={{ border: 'solid 1px lightgray' }}>
        <div>
          <h3 style={{ display: 'inline-block', marginRight: 15 }}>
            {encounter.title ? encounter.title : encounter.hashId}
          </h3>
          <span style={{ color: `${grayscale.lightDark}` }}>
            {encounter.title && encounter.hashId}
          </span>
        </div>
        {encounter.description && (
          <div className="description">
            {truncate(encounter.description, 150)}
          </div>
        )}

        <div className="small-data">
          <span>
            <strong>Type:</strong> {encounter.encounterType}
          </span>
          <span>
            <strong>Author:</strong> {encounter.author.username}
          </span>
          <span>
            <strong>Date Created:</strong> {readableDate}
          </span>
          <div>
            <strong>Flags:</strong>{' '}
            <span className="metadata">
              Incomplete: {encounter.incomplete ? 'Yes' : 'No'}
            </span>
            <span className="metadata">
              Requires Attention: {encounter.attention ? 'Yes' : 'No'}
            </span>
            <span className="metadata">
              Public: {encounter.isPublic ? 'Yes' : 'No'}
            </span>
            <span className="metadata">
              Clinic: {encounter.clinicEncounter ? 'Yes' : 'No'}
            </span>
          </div>
        </div>
      </Block>
    </EncounterCardContainer>
  );
}

const EncounterCardContainer = styled.div`
  h3 {
    margin-top: 0;
    margin-bottom: 3px;
    font-size: 1em;
  }
  .description {
    margin-bottom: 15px;
    font-size: 0.75em;
    line-height: 100%;
  }
  .small-data {
    color: ${grayscale.lightDark};
    font-size: 0.65em;
    line-height: 140%;
    span {
      margin-right: 15px;
    }
  }
  ${Block} {
    padding: 15px;
  }
`;
