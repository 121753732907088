import React, { useEffect, useState } from 'react';
// DEPENDENCIES
// COMPONENTS
import { SolidButton } from '@bit/medzcool.cytoplasm.buttons';

// STYLES
import styled from 'styled-components';

// Apollo, GraphQL
import { useQuery, useLazyQuery } from '@apollo/client';
import { GET_ENCOUNTERS } from '../DataStructures/Queries';

export default function FilterSidebar(props) {
  const { refetch } = props;
  const [flags, setFlags] = useState({
    attention: false,
    isPublic: false,
    clinicEncounter: false,
    incomplete: false,
  });

  const [search, setSearch] = useState('');
  function searchText(e) {
    const searchVal = e.target.value;
    setSearch(searchVal);
    // console.log(searchVal);
  }

  function onFlagChange(flag, flagValue) {
    if (flag == 'attention') {
      setFlags({
        ...flags,
        attention: flagValue,
      });
    }
    if (flag == 'isPublic') {
      setFlags({
        ...flags,
        isPublic: flagValue,
      });
    }
    if (flag == 'clinicEncounter') {
      setFlags({
        ...flags,
        clinicEncounter: flagValue,
      });
    }
    if (flag == 'incomplete') {
      setFlags({
        ...flags,
        incomplete: flagValue,
      });
    }
  }

  function refetchConfirm() {
    console.log(search);
    refetch({ search: search, flags: JSON.stringify(flags) });
    // refetch({ search });
  }

  return (
    <FilterSidebarContainer>
      <h3>Filters</h3>

      <div className="search">
        <h4>Search</h4>
        <input type="text" value={search} onChange={(e) => searchText(e)} />
      </div>

      <h4>Flags</h4>

      <div className="flag-block">
        <input
          type="checkbox"
          id="attention"
          name="attention"
          value="attention"
          checked={flags.attention}
          className="checkbox"
          onChange={() => onFlagChange('attention', !flags.attention)}
        />
        <label className="checkbox-text" htmlFor="attention">
          Requires Attention
        </label>
      </div>

      <div className="flag-block">
        <input
          type="checkbox"
          id="isPublic"
          name="isPublic"
          value="isPublic"
          checked={flags.isPublic}
          className="checkbox"
          onChange={() => onFlagChange('isPublic', !flags.isPublic)}
        />
        <label className="checkbox-text" htmlFor="isPublic">
          Public Encounter
        </label>
      </div>

      <div className="flag-block">
        <input
          type="checkbox"
          id="clinicEncounter"
          name="clinicEncounter"
          value="clinicEncounter"
          checked={flags.clinicEncounter}
          className="checkbox"
          onChange={() =>
            onFlagChange('clinicEncounter', !flags.clinicEncounter)
          }
        />
        <label className="checkbox-text" htmlFor="clinicEncounter">
          Clinic Encounters
        </label>
      </div>

      <div className="flag-block">
        <input
          type="checkbox"
          id="incomplete"
          name="incomplete"
          value="incomplete"
          checked={flags.incomplete}
          className="checkbox"
          onChange={() => onFlagChange('incomplete', !flags.incomplete)}
        />
        <label className="checkbox-text" htmlFor="incomplete">
          Incomplete Encounters
        </label>
      </div>

      <SolidButton style={{ marginTop: 25 }} onClick={() => refetchConfirm()}>
        Filter Encounters
      </SolidButton>
    </FilterSidebarContainer>
  );
}
const FilterSidebarContainer = styled.div`
  .flag-block {
    margin-bottom: 25px;
    label {
      cursor: pointer;
    }
  }
  .checkbox {
    height: 20px;
    width: 20px;
    cursor: pointer;
  }
  .checkbox-text {
    margin-left: 5px;
  }
`;
