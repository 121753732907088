import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import "./Draft.css";
import * as serviceWorker from "./serviceWorker";
import { url } from "./settings";


// Dependencies
import { BrowserRouter } from "react-router-dom";

// Apollo
import { ApolloProvider, InMemoryCache, ApolloClient } from "@apollo/client";

const client = new ApolloClient({
  cache: new InMemoryCache(),
  uri: `${url}/graphql/`,
  resolvers: {},
  credentials: "include",
});

ReactDOM.render(
  <ApolloProvider client={client}>
  <BrowserRouter>
    <App client={client} />
    </BrowserRouter>
  </ApolloProvider>,
  document.getElementById("root")
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
