import React, { useState, useEffect } from 'react';
// DEPENDENCIES

// COMPONENTS
import Editor from '@bit/medzcool.cytoplasm.editor';
import ReadOnly from '@bit/medzcool.cytoplasm.readonly';
import SectionHeader from './SectionHeader';

// STYLES

// DATA
import { gql, useMutation } from '@apollo/client';

const EDIT_DOCUMENT = gql`
  mutation EditDocument($id: Int!, $title: String!, $body: String!) {
    editDocument(id: $id, title: $title, body: $body) {
      document {
        id
        title
        body
        metadata
      }
    }
  }
`;

export default function Document(props) {
  const { document, activeLogic, user, documentType } = props;

  const [editing, setEditing] = useState(false);

  const [documentTitle, setDocumentTitle] = useState(document.title);
  function editDocumentTitle(e) {
    setDocumentTitle(e.target.value);
  }
  const [documentBody, setDocumentBody] = useState(document.body);
  function exportEditorContent(content) {
    setDocumentBody(content);
  }
  useEffect(() => {
    setDocumentTitle(document.title);
    setDocumentBody(document.body);
  }, [activeLogic]);

  const [editDocument] = useMutation(EDIT_DOCUMENT);
  function saveDocument() {
    editDocument({
      variables: {
        id: document.id,
        title: documentTitle,
        body: documentBody,
      },
    });
  }

  return (
    <>
      <SectionHeader
        title={document.title}
        save={saveDocument}
        editing={editing}
        setEditing={setEditing}
      />

      {editing ? (
        <>
          {documentType != 'note' && (
            <input
              type="text"
              value={documentTitle}
              onChange={editDocumentTitle}
            />
          )}

          <div className="serif">
            <Editor
              exportEditorContent={exportEditorContent}
              content={documentBody}
              user={user}
            />
          </div>
        </>
      ) : documentBody ? (
        <div className="serif">
          <ReadOnly content={documentBody} />
        </div>
      ) : null}
    </>
  );
}
