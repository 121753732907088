import React, { useState } from 'react';

// Dependencies

// Components
import ListEncounters from './FilterEncounters/ListEncounters';
import Header from '../Navigation/Header';

// Styles
import styled from 'styled-components';
import { grayscale } from '@bit/medzcool.cytoplasm.colors';

import { gql, useMutation } from '@apollo/client';
import { CREATE_ENCOUNTER } from './DataStructures/Mutations';

export default function SelectEncounterType(props) {
  const { history, user } = props;

  const [createEncounter, { called, loading, error, data }] = useMutation(
    CREATE_ENCOUNTER,
    {
      onCompleted(data) {
        const hashId = data.createEncounter.encounter.hashId;
        history.push(`/edit/${hashId}`);
      },
    },
  );

  function createEncounterConfirm(encounterType) {
    createEncounter({
      variables: {
        encounterType: encounterType,
        userId: user.id,
      },
    });
  }

  if (called && loading) {
    return (
      <CreateCaseContainer>
        <p>Loading...</p>
      </CreateCaseContainer>
    );
  }

  if (error) {
    return (
      <CreateCaseContainer>
        <h3>There was a server error creating the Encounter</h3>
      </CreateCaseContainer>
    );
  }

  return (
    <>
      <Header {...props} />
      <CreateCaseContainer>
        <CreateHeader>
          <Button onClick={() => createEncounterConfirm('single-question')}>
            <div className="button-label">
              <h3 className="main">Create 'single-question'</h3>
              <p>One clinical scenario, one question/answer</p>
            </div>
          </Button>
          <Button onClick={() => createEncounterConfirm('multiple-questions')}>
            <div className="button-label">
              <h3 className="main">Create 'multiple-questions'</h3>
              <p>One clinical scenario, multiple questions</p>
            </div>
          </Button>
          <Button onClick={() => createEncounterConfirm('sequential')}>
            <div className="button-label">
              <h3 className="main">Create 'sequential'</h3>
              <p>Multiple clinical scenarios, multiple questions</p>
            </div>
          </Button>
        </CreateHeader>

        <ListEncounters {...props} />
      </CreateCaseContainer>
    </>
  );
}

const CreateHeader = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 15px;
  padding-bottom: 15px;
`;

const CreateCaseContainer = styled.div`
  margin: auto;
  max-width: 1200px;
`;

const Button = styled.button`
  display: block;
  margin-bottom: 15px;
  padding: 25px;
  border: none;
  background-color: ${grayscale.darkDark};
  color: white;
  text-align: left;
  width: 100%;
  .button-label {
    font-family: futura-pt, sans-serif;
    margin-left: 15px;
    h3 {
      font-size: 22px;
      margin: 0;
    }
    p {
      font-size: 16px;
      font-family: futura-pt, sans-serif;
    }
  }
`;
