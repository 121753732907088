import React, { useEffect, useState } from 'react';
// DEPENDENCIES
// COMPONENTS

// STYLES
import styled from 'styled-components';
import { blue, green, red } from '@bit/medzcool.cytoplasm.colors';

export default function VoteCount(props) {
  const { choice, submitted, totalVotes } = props;

  const [percent, setPercent] = useState();
  useEffect(() => {
    setPercent(choice.votes / totalVotes);
  }, [totalVotes]);

  if (!submitted) return null;

  return (
    <VoteCountContainer
      percent={percent}
      correctAnswer={choice.correctAnswer}
    />
  );
}
const VoteCountContainer = styled.div`
  position: absolute;
  opacity: 10%;
  left: 0;
  top: 0;
  height: 100%;
  width: ${(props) => props.percent * 100}%;
  background-color: ${(props) =>
    props.correctAnswer ? green.bright : red.bright};
`;
