import React from 'react';
// DEPENDENCIES
// COMPONENTS
import LiveEncounter from './LiveEncounter';
// STYLES

// Apollo, GraphQL
import { useQuery, useMutation } from '@apollo/client';
import { GET_ENCOUNTER } from '../DataStructures/Queries';
import { ANSWER_ENCOUNTER, CREATE_EVENT } from '../DataStructures/Mutations';

export default function LiveDataContainer(props) {
  const hashId = props.match.params.hashid;

  const [answerEncounter] = useMutation(ANSWER_ENCOUNTER, {
    onCompleted(data) {
      console.log(data);
    },
  });
  const [createEvent] = useMutation(CREATE_EVENT);

  const { loading, error, data } = useQuery(GET_ENCOUNTER, {
    variables: { hashId },
  });

  if (loading) return null;
  if (error) return <h1>Server Error</h1>;

  if (!data.encounter) return null;

  return (
    <LiveEncounter
      encounter={data.encounter}
      answerEncounter={answerEncounter}
      user={props.user}
      createEvent={createEvent}
      eventLocation="preview"
      // inCollection={true}
      // clinicEncounterAnswered={true}
      {...props}
    />
  );
}
