import React, { useState, useEffect } from 'react';

// Dependencies
import { Link } from 'react-router-dom';

// Components
import Document from './EditDocument';
import Note from './EditNote';

import Question from './EditQuestion';
import Metadata from './EditMetadata';
import EditSidebar from './EditSidebar';
import { SolidButton, BorderButton } from '@bit/medzcool.cytoplasm.buttons';
import CollectionModal from './CollectionModal';

// Styles
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/pro-light-svg-icons';
import { faLayerPlus } from '@fortawesome/pro-regular-svg-icons';
import { grayscale, yellow } from '@bit/medzcool.cytoplasm.colors';

// Apollo, GraphQL
import { useQuery, useMutation } from '@apollo/client';
import { GET_ENCOUNTER } from '../DataStructures/Queries';
import {
  CREATE_QUESTION,
  CREATE_SEQUENCE,
  CREATE_NOTE,
} from '../DataStructures/Mutations';

export default function EditEncounter(props) {
  const { user } = props;

  const [editingView, setEditingView] = useState('main');

  const [encounter, setEncounter] = useState();
  const [activeLogicOrder, setActiveLogicOrder] = useState();
  const [activeLogic, setActiveLogic] = useState();

  const hashId = props.match.params.hashid;
  const { loading, error, data } = useQuery(GET_ENCOUNTER, {
    variables: { hashId },
    onCompleted: (data) => {
      setActiveLogic(data.encounter.encounterlogicSet[0]);
      setActiveLogicOrder(0);
    },
  });

  const [createNote] = useMutation(CREATE_NOTE);

  useEffect(() => {
    if (!loading && !error) {
      setEncounter(data.encounter);
      if (activeLogicOrder || activeLogicOrder == 0) {
        setActiveLogic(data.encounter.encounterlogicSet[activeLogicOrder]);
      }
    }
  }, [data]);

  const [openState, setOpenState] = useState();
  function activateModal() {
    setOpenState(true);
  }

  if (loading) return <div>Loading...</div>;
  if (error) return <h1>Server Error</h1>;

  if (!encounter || !activeLogic) return null;

  return (
    <>
      <HeaderContainer>
        <div className="left">
          <div className="home" onClick={() => props.history.push('/')}>
            <FontAwesomeIcon icon={faHome} size="lg" />
          </div>
        </div>
        <div className="right">
          <div></div>
          <SolidButton
            style={{
              background: `${yellow.light}`,
              color: `black`,
              fontWeight: 'bold',
            }}
            onClick={activateModal}
          >
            <FontAwesomeIcon icon={faLayerPlus} size="lg" />
          </SolidButton>
          <SolidButton
            style={{
              background: `${yellow.light}`,
              color: `black`,
              fontWeight: 'bold',
            }}
            onClick={() => props.history.push(`/preview/${hashId}`)}
          >
            Preview
          </SolidButton>
        </div>
      </HeaderContainer>
      <EditEncounterContainer>
        <EditSidebar
          setEditingView={setEditingView}
          editingView={editingView}
          activeLogic={activeLogic}
          setActiveLogic={setActiveLogic}
          setActiveLogicOrder={setActiveLogicOrder}
          encounter={encounter}
          {...props}
        />

        <div className="right">
          {editingView == 'summary' && (
            <>
              <h1>Summary</h1>
              <LogicContainer>
                <Document
                  document={encounter.summary}
                  activeLogic={activeLogic}
                />
              </LogicContainer>
            </>
          )}
          {editingView == 'main' && (
            <>
              <h1>Create Encounter</h1>

              <LogicContainer>
                <Document
                  document={activeLogic.document}
                  user={user}
                  activeLogic={activeLogic}
                />

                <hr
                  style={{
                    marginBottom: 50,
                    marginTop: 75,
                    color: 'lightgray',
                  }}
                />

                <Question question={activeLogic.question} />

                <hr
                  style={{
                    marginBottom: 50,
                    marginTop: 75,
                    color: 'lightgray',
                  }}
                />

                <Document
                  document={activeLogic.explanation}
                  user={user}
                  activeLogic={activeLogic}
                />
              </LogicContainer>
            </>
          )}
          {editingView == 'notes' && (
            <>
              <h1>Notes</h1>

              <LogicContainer>
                {encounter.noteSet.map((note) => {
                  if (note.user && user.id == note.user.id) {
                    return (
                      <Note
                        key={note.id}
                        note={note}
                        user={note.user}
                        encounter={encounter}
                      />
                    );
                  }
                })}
                <BorderButton
                  color="gray"
                  border="none"
                  style={{ marginTop: 15, textAlign: 'left', padding: 0 }}
                  onClick={() =>
                    createNote({
                      variables: {
                        encounterId: encounter.id,
                        userId: user.id,
                      },
                    })
                  }
                >
                  <span>New Note</span>
                </BorderButton>
              </LogicContainer>
            </>
          )}
          {editingView == 'metadata' && (
            <>
              <h1>Metadata</h1>

              <LogicContainer>
                <Metadata encounter={encounter} user={user} />
              </LogicContainer>
            </>
          )}
        </div>
      </EditEncounterContainer>
      <CollectionModal openState={openState} setOpenState={setOpenState} encounter={encounter}/>
    </>
  );
}
const EditEncounterContainer = styled.div`
  display: grid;
  grid-gap: 50px;
  grid-template-columns: 1.5fr 5fr;
  max-width: 1000px;
  margin: auto;
  margin-top: 25px;
`;

const LogicContainer = styled.div`
  input {
    padding: 15px;
    border: 1px solid lightgray;
    border-radius: 4px;
    display: inline-block;
    box-sizing: border-box;
    font-size: 1em;
    margin-bottom: 15px;
    width: 100%;
  }
`;
const HeaderContainer = styled.div`
  position: fixed;
  box-sizing: border-box;
  padding: 8px;
  left: 0px;
  top: 0px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05);
  z-index: 99;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
  border-bottom: 1px solid ${grayscale.gray};
  .right {
    display: grid;
    grid-template-columns: auto min-content auto;
    grid-gap: 10px;
  }
  .left {
    display: grid;
    grid-template-columns: min-content 1fr 1fr;
    grid-gap: 10px;
    align-items: center;
    .home {
      cursor: pointer;
    }
  }
  .back-container {
    cursor: pointer;
  }
`;
// EditEncounter.js will have four views on the right
// 1. the `main` of the case
// 2. editing `summary`
// 3. view/edit `notes`
// 4. `metadata` (tags, categories, etc.)
