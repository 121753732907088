import React, { useState } from 'react';
// DEPENDENCIES
// COMPONENTS
import { BorderButton, SolidButton } from '@bit/medzcool.cytoplasm.buttons';

// STYLES
import styled from 'styled-components';
import { grayscale } from '@bit/medzcool.cytoplasm.colors';

export default function SectionHeader(props) {
  const { title, save, editing, setEditing } = props;

  function handleSave() {
    setEditing(!editing);
    save();
  }

  return (
    <HeaderContainer>
      {!editing ? <h2>{title}</h2> : <h2>Editing</h2>}

      <div className="toggle-edit">
        {editing ? (
          <div className="save-cancel">
            <SolidButton onClick={handleSave}>Save</SolidButton>
            <SolidButton
              background={grayscale.lightGray}
              color={grayscale.lightDark}
              onClick={() => setEditing(!editing)}
            >
              Cancel
            </SolidButton>
          </div>
        ) : (
          <div>
            <BorderButton onClick={() => setEditing(!editing)}>
              Edit
            </BorderButton>
          </div>
        )}
      </div>
    </HeaderContainer>
  );
}
const HeaderContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  .toggle-edit {
    justify-self: end;
    .save-cancel {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 15px;
    }
  }
`;
