import React from 'react';

// DEPENDENCIES

// COMPONENTS
import { Block } from '../PreviewComponents/Block/Block';
import { SolidButton } from '@bit/medzcool.cytoplasm.buttons';
import EncounterCard from './EncounterCard';
import FilterSidebar from './FilterSidebar';

// STYLES
import styled from 'styled-components';
import { grayscale } from '@bit/medzcool.cytoplasm.colors';

// Apollo, GraphQL
import { useQuery, gql } from '@apollo/client';
import { GET_ENCOUNTERS } from '../../DataStructures/Queries';

// const GET_ENCOUNTERS = gql`
//   query encounters(
//     $offset: Int
//     $limit: Int
//     $search: String
//     $flags: JSONString
//   ) {
//     encounters(offset: $offset, limit: $limit, search: $search, flags: $flags) {
//       id
//       hashId
//       encounterType
//       title
//       description
//       thumbnail
//       author {
//         id
//         username
//       }
//       attention
//       isPublic
//       clinicEncounter
//       incomplete
//       dateCreated
//       noteSet {
//         id
//         date
//         text
//         user {
//           id
//         }
//       }
//       tags {
//         id
//         name
//       }
//       documentSet {
//         id
//         title
//         body
//         metadata
//       }
//       questionSet {
//         id
//       }
//       summary {
//         id
//         title
//         body
//         metadata
//       }
//       encounterlogicSet {
//         id
//         order
//         document {
//           id
//           title
//           body
//           metadata
//         }
//         question {
//           id
//           text
//           votes
//           choiceSet {
//             id
//             text
//             correctAnswer
//             votes
//           }
//         }
//         explanation {
//           id
//           title
//           body
//           metadata
//         }
//       }
//     }
//   }
// `;


export default function ListEncounters(props) {
  const { history } = props;

  const { loading, error, data, fetchMore, refetch } = useQuery(
    GET_ENCOUNTERS,
    {
      variables: {
        offset: 0,
        limit: 10,
      },
    },
  );

  function fetchMoreEncounters() {
    fetchMore({
      variables: {
        offset: data.encounters.length,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return Object.assign({}, prev, {
          encounters: [...prev.encounters, ...fetchMoreResult.encounters],
        });
      },
    });
  }

  if (loading) return <div>Loading...</div>;
  if (error) return <h1>Server Error</h1>;

  return (
    <ListEncountersContainer>
      <FilterSidebar refetch={refetch} />
      <Encounters>
        <h2>Encounter(s)</h2>
        <div>
          {data.encounters.map((encounter) => {
            return (
              <EncounterCard
                key={encounter.id}
                encounter={encounter}
                {...props}
              />
            );
          })}
        </div>
        <SolidButton onClick={fetchMoreEncounters}>
          Fetch More Encounters
        </SolidButton>
      </Encounters>
    </ListEncountersContainer>
  );
}
const ListEncountersContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 5fr;
  grid-gap: 35px;
  ${Block} {
    cursor: pointer;
    margin-bottom: 15px;
    border: 1px solid ${grayscale.gray};
  }
`;
const Sidebar = styled.div``;
const Encounters = styled.div``;
