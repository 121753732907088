import React from 'react';
import './App.css';

// Dependencies
import { Route, Switch } from 'react-router-dom';

// Components
import SelectEncounterType from './Encounters/SelectEncounterType';
import EditEncounter from './Encounters/EditComponents/EditEncounter';
import CollectionList from './Collections/CollectionList';
import Collection from './Collections/Collection';
import LiveDataContainer from './Encounters/LiveEncounter/LiveDataContainer';
import Header from './Navigation/Header';

// Styles
import styled from 'styled-components';
import { BaseType } from '@bit/medzcool.cytoplasm.typography';

// Apollo, GraphQL
import { gql, useQuery } from '@apollo/client';

const GET_USER = gql`
  query GET_USER {
    user {
      id
      isSuperuser
      username
    }
  }
`;

export default function App(props) {
  const { loading, error, data } = useQuery(GET_USER);
  if (loading) return null;
  if (error) return <h1>Server Error</h1>;
  const user = data.user;

  if (!user.isSuperuser) return null;

  return (
    <BaseType style={{ maxWidth: '100%' }}>
      <Switch>
        <Route
          exact
          path="/"
          render={(props) => <SelectEncounterType {...props} user={user} />}
        />
        <Route
          path="/edit/:hashid/"
          render={(props) => <EditEncounter {...props} user={user} />}
        />
        <Route
          path="/preview/:hashid/"
          render={(props) => <LiveDataContainer {...props} user={user} />}
        />
        <Route
          exact
          path="/collections"
          render={(props) => <CollectionList {...props} user={user} />}
        />
        <Route
          exact
          path="/collection/:collectionUrl"
          render={(props) => <Collection {...props} user={user} />}
        />
      </Switch>
    </BaseType>
  );
}

const Body = styled.div`
  margin-top: 50px;
`;
const HeaderGrid = styled.div`
  display: grid;
  grid-gap: 50px;
  grid-template-columns: min-content min-content;
  .header-nav {
    cursor: pointer;
  }
`;
