import React, { useState } from 'react';
// DEPENDENCIES
import ContentEditable from 'react-contenteditable';

// COMPONENTS

// STYLES
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-light-svg-icons';
import { grayscale, green } from '@bit/medzcool.cytoplasm.colors';

// DATA

export default function Choice(props) {
  const {
    choice,
    selectCorrectAnswer,
    correctAnswer,
    deleteChoice,
    question,
    localChoiceData,
    setLocalChoiceData,
    editing,
    saveQuestion,
  } = props;

  const [choiceText, setChoiceText] = useState(choice.text);
  function editChoiceText(e) {
    // When a Choice is edited, it gets the choiceSet of the question.
    // The object of choice.id is found in this choiceSet and replaced by the new edit
    // a new choiceData state is set.
    const rawHTML = e.target.value;
    const stripedHtml = rawHTML.replace(/<[^>]+>/g, '');

    setChoiceText(stripedHtml);

    // 1. FIND CHOICE BY ID
    const localChoice = localChoiceData.find(
      (objectChoice) => objectChoice.id == choice.id,
    );

    // 2. CREATE NEW CHOICE OBJECT WHICH CAN BE EDITABLE
    const newLocalChoice = { ...localChoice };

    // 3. SET CHOICE TEXT TO THE TARGET OF THIS INPUT
    newLocalChoice.text = stripedHtml;
    // console.log(newLocalChoice)

    // 4. COLLECT ALL CHOICES INTO A NEW localChoiceData
    const spreadLocalChoiceData = localChoiceData.map((choice) => {
      return { ...choice };
    });

    const existingChoice = spreadLocalChoiceData.find(
      (objectChoice) => objectChoice.id == choice.id,
    );
    const newObject = Object.assign(existingChoice, newLocalChoice);
    setLocalChoiceData(spreadLocalChoiceData);
  }

  function deleteChoiceConfirm() {
    const confirm = window.confirm(
      'Are you sure you want to delete this answer choice?',
    );
    if (confirm) {
      saveQuestion(false);
      deleteChoice({
        variables: {
          questionId: question.id,
          choiceId: choice.id,
        },
      });
    }
  }

  function isChecked(choice) {
    if (!correctAnswer) return null;

    if (correctAnswer.id == choice.id) {
      return 'checked';
    }
  }

  if (!localChoiceData) return null;

  if (editing) {
    return (
      <ChoicesContainerGrid>
        <FontAwesomeIcon icon={faTrash} onClick={deleteChoiceConfirm} />

        <ContentEditable
          html={choiceText} // innerHTML of the editable div
          disabled={false} // use true to disable editing
          onChange={editChoiceText} // handle innerHTML change
          className="textarea"
        />
        <div>
          <label>
            <input
              className="input-radio"
              type="radio"
              name={question.id}
              value={choice.id}
              onClick={() => selectCorrectAnswer(choice)}
            />
            <Radio checked={isChecked(choice)} />
            <CorrectLabel checked={isChecked(choice)}>
              Correct Answer
            </CorrectLabel>
          </label>
        </div>
      </ChoicesContainerGrid>
    );
  }
  return (
    <ChoicesContainer>
      <ContentEditable disabled={true} html={choiceText} />
    </ChoicesContainer>
  );
}
const ChoicesContainerGrid = styled.div`
  display: grid;
  grid-template-columns: min-content 5fr max-content;
  grid-gap: 15px;
  margin-bottom: 15px;
  align-items: center;
  width: 100%;
  .textarea {
    padding: 10px;
    border: 1px solid lightgray;
    border-radius: 4px;
    display: inline-block;
    box-sizing: border-box;
    width: 100%;
  }
  input.input-radio {
    display: none;
  }
`;
const ChoicesContainer = styled.div`
  padding: 20px 15px;
  border: 1px solid ${grayscale.lightGray};
  border-radius: 5px;
  background-color: #f8f8f8;
  margin-bottom: 8px;
  cursor: pointer;
`;
const Radio = styled.div`
  display: inline-block;
  border-radius: 100%;
  height: 18px;
  width: 18px;
  vertical-align: middle;
  cursor: pointer;
  border: 4px solid
    ${(props) => (props.checked ? `${green.bright}` : `${grayscale.lightDark}`)};
  background: ${(props) => props.checked && `${green.bright}`};
`;
const CorrectLabel = styled.div`
  color: ${(props) => props.checked && `${green.bright}`};

  cursor: pointer;
  display: inline-block;
  margin-left: 15px;
`;
