import React, { useEffect, useState } from 'react';
// DEPENDENCIES
import axios from 'axios';
import { convertFromRaw, EditorState } from 'draft-js';
import { url } from '../../settings';

// COMPONENTS
import { Block } from '../PreviewComponents/Block/Block';
import { SolidButton } from '@bit/medzcool.cytoplasm.buttons';
import Tags from './EditTags';
import Flags from './EditFlags';
import Identity from './EditIdentity';
import Thumbnail from './EditThumbnail';
import TitleDescription from './EditTitleDescription';

// STYLES
import styled from 'styled-components';
import { grayscale } from '@bit/medzcool.cytoplasm.colors';
import { fontFamily } from '@bit/medzcool.cytoplasm.typography';

import { gql, useMutation } from '@apollo/client';
import {
  CREATE_CHOICE,
  EDIT_QUESTION,
  EDIT_TITLE_DESCRIPTION,
  EDIT_FLAG,
} from '../DataStructures/Mutations';

const EDIT_DOCUMENT = gql`
  mutation EditDocument($id: Int!, $title: String!, $body: String!) {
    editDocument(id: $id, title: $title, body: $body) {
      document {
        id
        title
        body
        metadata
      }
    }
  }
`;

export default function EditMetadata(props) {
  const { encounter, user } = props;

  const [oldHash, setOldHash] = useState();
  function onChange(e) {
    const hashValue = e.target.value;
    setOldHash(hashValue);
  }

  const [editDocument] = useMutation(EDIT_DOCUMENT);
  const [editQuestion] = useMutation(EDIT_QUESTION);
  const [createChoice] = useMutation(CREATE_CHOICE);
  const [editTitleDescription] = useMutation(EDIT_TITLE_DESCRIPTION);
  const [editFlag] = useMutation(EDIT_FLAG);

  function getOldEncounter() {
    axios({
      method: 'post',
      url: 'https://codehealth.io/api/v0.1/fetch-case/',
      data: {
        caseId: oldHash,
      },
    })
      .then(function (response) {
        // console.log(response.data.data);
        const explanation = response.data.data.explanation[0].explanation;
        const explanationDocument = encounter.documentSet.find((document) =>
          document.metadata.includes('explanation'),
        );
        editDocument({
          variables: {
            id: explanationDocument.id,
            title: 'Explanation',
            body: explanation,
          },
        });

        const stem = response.data.data.stems[0].stem;
        const stemDocument = encounter.documentSet.find((document) =>
          document.metadata.includes('stem'),
        );

        editDocument({
          variables: {
            id: stemDocument.id,
            title: 'Clinical Scenario',
            body: stem,
          },
        });

        const questionEditorState = EditorState.createWithContent(
          convertFromRaw(JSON.parse(response.data.data.questions[0].question)),
        );
        const questionText = questionEditorState
          .getCurrentContent()
          .getPlainText('\u0001');

        editQuestion({
          variables: {
            id: encounter.questionSet[0].id,
            text: questionText,
          },
        });

        const choices = response.data.data.questions[0].choices;
        choices.map((choice) => {
          const choiceEditorState = EditorState.createWithContent(
            convertFromRaw(JSON.parse(choice.choice_textfield)),
          );
          const choiceText = choiceEditorState
            .getCurrentContent()
            .getPlainText('\u0001');

          createChoice({
            variables: {
              questionId: encounter.questionSet[0].id,
              text: choiceText,
              correctAnswer: choice.correct_answer,
            },
          });
        });
      })
      .then(function (response) {
        axios({
          method: 'post',
          url: `${url}/rounds/get-encounter/`,
          data: {
            hashId: oldHash,
          },
        }).then(function (response) {

          if (response.title) {
            editTitleDescription({
              variables: {
                title: response.data.title,
                description: response.data.description,
                encounterId: encounter.id,
              },
            });
          }
         
        });
        return;
      });
  }

  return (
    <MetadataContainer>
      <Block>
        <input type="text" value={oldHash} onChange={(e) => onChange(e)} />
        <SolidButton onClick={getOldEncounter}>Get Old Encounter</SolidButton>
      </Block>

      <Block>
        <Identity encounter={encounter} user={user} />
      </Block>

      <Block>
        <TitleDescription encounter={encounter} />
      </Block>

      <Block>
        <Thumbnail encounter={encounter} />
      </Block>

      <Block>
        <Tags encounter={encounter} />
      </Block>

      <Block>
        <Flags encounter={encounter} />
      </Block>
    </MetadataContainer>
  );
}
const MetadataContainer = styled.div`
  ${Block} {
    margin-bottom: 25px;
    border: 1px solid ${grayscale.gray};
  }
  input {
    font-family: ${fontFamily.serif};
  }
  textarea {
    font-family: ${fontFamily.serif};
    padding: 15px;
    border: 1px solid lightgray;
    border-radius: 4px;
    display: inline-block;
    box-sizing: border-box;
    font-size: 1em;
    margin-bottom: 15px;
  }
`;
