import React from 'react';
// DEPENDENCIES

// COMPONENTS
import { Block } from '../Encounters/PreviewComponents/Block/Block';
import Header from '../Navigation/Header';

// STYLES
import styled from 'styled-components';
import { grayscale } from '@bit/medzcool.cytoplasm.colors';

// Apollo, GraphQL
import { useQuery } from '@apollo/client';
import { GET_COLLECTIONS } from '../DataStructures/Queries';

export default function CollectionList(props) {
  const { loading, error, data } = useQuery(GET_COLLECTIONS);

  if (loading) return null;
  if (error) return <h1>Server Error</h1>;

  return (
    <>
      <Header {...props} />
      <CollectionListContainer>
        <h1>Collections</h1>
        {data.collections.map((collection) => {
          return (
            <CollectionContainer
              key={collection.id}
              onClick={() =>
                props.history.push(`/collection/${collection.collectionUrl}`)
              }
            >
              <Block>
                <h2 className="title">{collection.collectionTitle}</h2>
                <p className="description">{collection.description}</p>
                <div className="metadata">
                  <div>{collection.encounterSet.length} Encounters</div>
                </div>
              </Block>
            </CollectionContainer>
          );
        })}
      </CollectionListContainer>
    </>
  );
}
const CollectionListContainer = styled.div`
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 25px;
`;
const CollectionContainer = styled.div`
  .title {
    font-size: 1.5em;
    line-height: 120%;
    margin-top: 0;
    margin-bottom: 5px;
  }
  .description {
    font-size: 0.75em;
    line-height: 100%;
    margin: 0;
  }
  .metadata {
    margin-top: 15px;
    color: ${grayscale.lightDark};
    font-size: 0.75em;
  }
  ${Block} {
    cursor: pointer;
    margin-bottom: 15px;
    border: 1px solid ${grayscale.gray};
    padding: 15px;
  }
`;
