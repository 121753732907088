import React, { useState, useEffect } from 'react';
// DEPENDENCIES
// COMPONENTS
import { Block } from '../PreviewComponents/Block/Block';

// STYLES
import styled from 'styled-components';
import { grayscale } from '@bit/medzcool.cytoplasm.colors';

export default function EncounterCard(props) {
  const { encounter, history } = props;

  const readableDate = new Date(encounter.dateCreated).toDateString();

  const [tags, setTags] = useState();
  useEffect(() => {
    let newArray = encounter.tags.reduce((newArray, tag) => {
      newArray.push(tag.name);
      return newArray;
    }, []);
    setTags(newArray.join(', '));
  }, []);

  function truncate(str, n) {
    return str.length > n ? str.substr(0, n - 1) + '...' : str;
  }

  return (
    <Block onClick={() => history.push(`/edit/${encounter.hashId}`)}>
      <EncounterCardContainer>
        <div>
          <h3 style={{ display: 'inline-block', marginRight: 15 }}>
            {encounter.title ? encounter.title : encounter.hashId}
          </h3>
          <span style={{ color: `${grayscale.lightDark}` }}>
            {encounter.title && encounter.hashId}
          </span>
        </div>
        {encounter.description && (
          <div className="description">
            {truncate(encounter.description, 150)}
          </div>
        )}

        <div className="small-data">
          <span>
            <strong>Type:</strong> {encounter.encounterType}
          </span>
          <span>
            <strong>Author:</strong> {encounter.author.username}
          </span>
          <span>
            <strong>Date Created:</strong> {readableDate}
          </span>
          <div>
            <strong>Tags:</strong> {tags}
          </div>
          <div>
            <strong>Flags:</strong>{' '}
            <span className="metadata">
              Incomplete: {encounter.incomplete ? 'Yes' : 'No'}
            </span>
            <span className="metadata">
              Requires Attention: {encounter.attention ? 'Yes' : 'No'}
            </span>
            <span className="metadata">
              Public: {encounter.isPublic ? 'Yes' : 'No'}
            </span>
            <span className="metadata">
              Clinic: {encounter.clinicEncounter ? 'Yes' : 'No'}
            </span>
          </div>
        </div>
      </EncounterCardContainer>
    </Block>
  );
}

const EncounterCardContainer = styled.div`
  h3 {
    margin-top: 0;
    margin-bottom: 8px;
  }
  .description {
    margin-bottom: 10px;
  }
  .small-data {
    color: ${grayscale.lightDark};
    font-size: 14px;
    span {
      margin-right: 15px;
    }
  }
`;
