import React, { useEffect, useState } from 'react';

// DEPENDENCIES

// COMPONENTS
import { SolidButton } from '@bit/medzcool.cytoplasm.buttons';

// STYLES
import styled from 'styled-components';
import { grayscale, blue } from '@bit/medzcool.cytoplasm.colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/pro-solid-svg-icons';

export default function Navigation(props) {
  const {
    logic,
    setLogic,
    selectedChoice,
    correctAnswer,
    setSubmitted,
    user,
    encounter,
    exitLocation,
    submitted,
    answerEncounter,
    eventLocation,
    inCollection,
  } = props;

  function handleSubmit() {
    setSubmitted(true);
    answerEncounter({
      variables: {
        userId: user ? user.id : null,
        encounterId: encounter.id,
        eventLocation: eventLocation,
        selectedChoices: JSON.stringify(logic.selectedChoices),
      },
    });

    // Reset logic.active to first question
    const updatedValues = {
      active: logic.set[0],
    };
    setLogic((prevState) => ({ ...prevState, ...updatedValues }));
    window.scrollTo(0, 0);
  }

  function nextLogic() {
    const updatedValues = {
      active: logic.set[logic.active.order + 1],
    };
    setLogic((prevState) => ({ ...prevState, ...updatedValues }));
  }
  function backLogic() {
    const updatedValues = {
      active: logic.set[logic.active.order - 1],
    };
    setLogic((prevState) => ({ ...prevState, ...updatedValues }));
  }

  function renderNavigationButtons(
    submitted,
    correctAnswer,
    logic,
    selectedChoice,
  ) {
    let back = (
      <SolidButton
        disabled={logic.active.order != 0 ? 0 : 1}
        onClick={backLogic}
        className="chevron"
      >
        <FontAwesomeIcon icon={faChevronLeft} size="lg" />
      </SolidButton>
    );
    let next = (
      <SolidButton
        disabled={
          !submitted &&
          selectedChoice &&
          logic.length > 1 &&
          logic.active.order + 1 != logic.length
            ? 0
            : 1
        }
        className="chevron"
        onClick={nextLogic}
      >
        <FontAwesomeIcon icon={faChevronRight} size="lg" />
      </SolidButton>
    );
    let main;

    // 1. If Encounter has no selected correct
    if (!correctAnswer) {
      return (
        <div
          style={{
            color: `${grayscale.gray}`,
            textAlign: 'center',
            marginTop: 25,
          }}
        >
          No correct answer selected
        </div>
      );
    }

    // 2. If Encounter is submitted
    if (submitted) {
      // If encounter is single-question
      if (logic.length <= 1) {
        next = null;
        back = null;
        if (inCollection) {
          main = null;
        } else {
          main = (
            <SolidButton
              className="main end-button"
              onClick={
                exitLocation
                  ? exitLocation
                  : () => props.history.push(`/edit/${encounter.hashId}`)
              }
            >
              Exit Encounter
            </SolidButton>
          );
        }
      }

      // if encounter has multiple questions
      if (logic.length > 1) {
        if (logic.active.order + 1 != logic.length) {
          main = (
            <SolidButton className="main multiple" onClick={nextLogic}>
              Next Question
            </SolidButton>
          );
          next = (
            <SolidButton
              disabled={logic.active.order + 1 != logic.length ? 0 : 1}
              className="chevron"
              onClick={nextLogic}
            >
              <FontAwesomeIcon icon={faChevronRight} size="lg" />
            </SolidButton>
          );
        }
        if (logic.active.order + 1 == logic.length) {
          if (inCollection) {
            main = (
              <SolidButton
                className="main multiple end-button"
                onClick={backLogic}
              >
                Previous Question
              </SolidButton>
            );
          } else {
            main = (
              <SolidButton
                className="main multiple end-button"
                onClick={
                  exitLocation
                    ? exitLocation
                    : () => props.history.push(`/edit/${encounter.hashId}`)
                }
              >
                Exit Encounter
              </SolidButton>
            );
          }
        }
      }

      return (
        <>
          {back}
          {next}
          {main}
        </>
      );
    }

    // if encounter has only 1 question
    if (logic.length <= 1) {
      return (
        <SolidButton
          className={!selectedChoice ? 'main' : 'main end-button'}
          disabled={!selectedChoice}
          onClick={handleSubmit}
        >
          {!selectedChoice ? 'Select Answer Choice' : 'Submit Answer'}
        </SolidButton>
      );
    }

    // if encounter has multiple questions
    if (logic.length > 1) {
      if (logic.active.order + 1 != logic.length) {
        main = (
          <SolidButton
            className="main multiple"
            disabled={!selectedChoice}
            onClick={nextLogic}
          >
            {!selectedChoice ? 'Select Answer Choice' : 'Next Question'}
          </SolidButton>
        );
      } else {
        main = (
          <SolidButton
            className={
              !selectedChoice ? 'main multiple' : 'main multiple end-button'
            }
            disabled={!selectedChoice}
            onClick={handleSubmit}
          >
            {!selectedChoice ? 'Select Answer Choice' : 'Submit Answer'}
          </SolidButton>
        );
      }
      return (
        <>
          <SolidButton
            disabled={logic.active.order != 0 ? 0 : 1}
            onClick={backLogic}
            className="chevron"
          >
            <FontAwesomeIcon icon={faChevronLeft} size="lg" />
          </SolidButton>

          <SolidButton
            disabled={
              selectedChoice &&
              logic.length > 1 &&
              logic.active.order + 1 != logic.length
                ? 0
                : 1
            }
            onClick={nextLogic}
            className="chevron"
          >
            <FontAwesomeIcon icon={faChevronRight} size="lg" />
          </SolidButton>

          {main}
        </>
      );
    }
  }
  const [renderedButtons, setRenderedButtons] = useState();
  useEffect(() => {
    const preRender = renderNavigationButtons(
      submitted,
      correctAnswer,
      logic,
      selectedChoice,
    );
    setRenderedButtons(preRender);
  }, [logic, submitted]);

  // console.log(encounter);
  // console.log('submitted', submitted);
  // console.log('correctAnswer', correctAnswer);
  // console.log('logic', logic);
  // console.log('selectedChoice', selectedChoice);
  console.log(inCollection);

  if (inCollection) {
    return (
      <NavContainer>
        <DesktopNavContainer
          logicLength={logic.length}
          inCollection={inCollection}
        >
          {renderedButtons}
        </DesktopNavContainer>
      </NavContainer>
    );
  }

  return (
    <NavContainer>
      <DesktopNavContainer logicLength={logic.length}>
        {renderedButtons}
      </DesktopNavContainer>

      <MobileNavContainer
        logicLength={logic.length}
        encounterType={encounter.encounterType}
      >
        {renderedButtons}
      </MobileNavContainer>
    </NavContainer>
  );
}
const NavContainer = styled.div`
  margin-top: 25px;
  .chevron {
    color: ${blue.bright};
    background: transparent;
    padding-left: 15px;
    padding-right: 15px;
    cursor: pointer;
    &:disabled {
      color: ${grayscale.gray};
      background: transparent;
    }
  }
  .main {
    padding: 15px;
    transition: all 0.1s ease 0s;
    box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    &:disabled {
      box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
    }
    &.multiple {
      margin-left: 15px;
      width: auto;
    }
  }
  .end-button {
    /* background-color: black; */
  }
`;

const DesktopNavContainer = styled.div`
  grid-gap: 10px;
  grid-template-columns: min-content min-content auto;
  ${(props) => props.logicLength > 1 && `display:grid`};

  @media (max-width: 768px) {
    ${(props) => !props.inCollection && `display:none`};
  }
`;

const MobileNavContainer = styled.div`
  position: Fixed;
  bottom: 0;
  box-sizing: border-box;
  left: 0;
  z-index: 99;
  text-align: center;
  width: 100%;
  display: none;
  background-color: ${grayscale.lightGray};
  padding: 10px 10px 35px 10px;
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.08);
  grid-gap: 15px;
  grid-template-columns: 1fr 1fr 3fr;
  ${(props) => props.encounterType !== 'single-question' && `padding-left:0`};
  @media (max-width: 768px) {
    display: block;
    ${(props) => props.logicLength > 1 && `display:grid`};
  }
`;
