import React, { useState, useEffect } from 'react';
// Dependencies

// Components
import { BorderButton } from '@bit/medzcool.cytoplasm.buttons';
import Choice from './EditChoice';
import SectionHeader from './SectionHeader';

// Styles
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-light-svg-icons';

// DATA
import { useMutation } from '@apollo/client';
import {
  EDIT_QUESTION,
  CREATE_CHOICE,
  DELETE_CHOICE,
} from '../DataStructures/Mutations';

export default function Question(props) {
  const { question } = props;

  const [editing, setEditing] = useState(false);

  const [questionText, setQuestionText] = useState();
  useEffect(() => {
    setQuestionText(question.text);
  }, [question]);

  function editQuestionText(e) {
    setQuestionText(e.target.value);
  }

  const [localChoiceData, setLocalChoiceData] = useState();

  const [editQuestion] = useMutation(EDIT_QUESTION);

  function saveQuestion(withChoiceSet = true) {
    editQuestion({
      variables: {
        id: question.id,
        text: questionText,
        choiceSet: withChoiceSet ? JSON.stringify(localChoiceData) : null,
      },
    });
  }

  const [createChoice] = useMutation(CREATE_CHOICE, {
    onCompleted(data) {
      const choice = data.createChoice.choice;
      const joined = localChoiceData.concat(choice);
      setLocalChoiceData(joined);
    },
  });
  function addNewChoice() {
    saveQuestion();
    createChoice({
      variables: { questionId: question.id },
    });
  }

  const [deleteChoice] = useMutation(DELETE_CHOICE, {
    onCompleted(data) {
      // This is the data set returned from the mutation after the choice was deleted.
      const choiceSet = data.deleteChoice.question.choiceSet;
      const arr1 = choiceSet;

      // This is the choiceSet that includes data with choice text, prior to deletion
      const arr2 = localChoiceData;

      // Return an array of objects comparing the intersection of arr2 and arr1
      const newLocalChoiceSet = arr2.filter((n) =>
        arr1.some((n2) => n.id == n2.id),
      );

      setLocalChoiceData(newLocalChoiceSet);
    },
  });

  const [correctAnswer, setCorrectAnswer] = useState();

  useEffect(() => {
    setLocalChoiceData(question.choiceSet);
    setCorrectAnswer(
      question.choiceSet.find((choiceObj) => choiceObj.correctAnswer == true),
    );
  }, [question]);

  function selectCorrectAnswer(choice) {
    let correctChoice = { ...choice };
    correctChoice.correctAnswer = true;
    setCorrectAnswer(correctChoice);

    const spreadLocalChoiceData = localChoiceData.map((choice) => {
      return { ...choice };
    });

    const existingChoice = spreadLocalChoiceData.find(
      (objectChoice) => objectChoice.id == correctChoice.id,
    );

    correctChoice.text = existingChoice.text;

    const newObject = Object.assign(existingChoice, correctChoice);

    const newLocalChoiceData = spreadLocalChoiceData.map((choiceObj) => {
      if (choiceObj.id != correctChoice.id) {
        choiceObj.correctAnswer = false;
      }
      return choiceObj;
    });
    setLocalChoiceData(newLocalChoiceData);
  }

  return (
    <div>
      <SectionHeader
        title={'Question'}
        save={saveQuestion}
        editing={editing}
        setEditing={setEditing}
      />

      {editing ? (
        <input
          className="serif"
          style={{ marginBottom: 25 }}
          type="text"
          value={questionText}
          onChange={editQuestionText}
        />
      ) : (
        <div className="serif" style={{ marginBottom: 25 }}>
          {questionText}
        </div>
      )}

      <div className="serif">
        {question.choiceSet.map((choice) => {
          return (
            <Choice
              key={choice.id}
              question={question}
              choice={choice}
              deleteChoice={deleteChoice}
              localChoiceData={localChoiceData}
              setLocalChoiceData={setLocalChoiceData}
              selectCorrectAnswer={selectCorrectAnswer}
              correctAnswer={correctAnswer}
              editing={editing}
              saveQuestion={saveQuestion}
            />
          );
        })}
      </div>

      {editing && (
        <BorderButton
          setWidth="auto"
          color="gray"
          border="none"
          onClick={addNewChoice}
        >
          <FontAwesomeIcon icon={faPlus} />
          <span style={{ marginLeft: 5 }}>Add Choice</span>
        </BorderButton>
      )}
    </div>
  );
}
