import React, { useEffect } from 'react';

// DEPENDENCIES
import ContentEditable from 'react-contenteditable';

// COMPONENTS
import VoteCount from './VoteCount';

// STYLES
import styled from 'styled-components';
import { grayscale, blue, green, red } from '@bit/medzcool.cytoplasm.colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/pro-solid-svg-icons';

export default function PreviewChoices(props) {
  const {
    logic,
    setLogic,
    submitted,
    selectedChoice,
    correctAnswer,
    isCorrect,
  } = props;

  function handleSelectedChoice(choice) {
    if (!submitted) {
      const spreadLogic = { ...logic };
      const arr1 = spreadLogic.selectedChoices;
      const found = spreadLogic.selectedChoices.find(
        (element) => element.questionId == logic.active.question.id,
      );

      const arr2 = [{ questionId: found.questionId, selectedChoice: choice }];

      // This map/find function compares arr1 with arr2
      // It returns a new array replacing properties in arr1 with updated properties from arr2
      const updatedSelectedChoices = arr1.map(
        (obj) => arr2.find((o) => o.questionId == obj.questionId) || obj,
      );

      const updatedValues = {
        selectedChoices: updatedSelectedChoices,
      };
      setLogic((prevState) => ({ ...prevState, ...updatedValues }));
    }
  }

  return (
    <>
      {logic.active.question.choiceSet.map((choice) => {
        return (
          <ChoicesContainer
            key={choice.id}
            onClick={() => handleSelectedChoice(choice)}
            isSelected={
              selectedChoice && choice.id == selectedChoice.id && 'selected'
            }
            isCorrect={
              correctAnswer &&
              choice.id == correctAnswer.id &&
              'correct'
            }
            submitted={submitted}
            className="serif"
          >
            <ContentEditable
              disabled={true}
              html={choice.text}
              style={{ position: 'relative', zIndex: 2 }}
            />

            {submitted && selectedChoice && choice.id == selectedChoice.id && (
              <Checkmark>
                {isCorrect ? (
                  <div className="icon">
                    <FontAwesomeIcon icon={faCheckCircle} size="lg" />
                  </div>
                ) : (
                  <div className="icon">
                    <FontAwesomeIcon icon={faTimesCircle} size="lg" />
                  </div>
                )}
              </Checkmark>
            )}

            <VoteCount
              choice={choice}
              submitted={submitted}
              totalVotes={logic.active.question.votes}
            />
          </ChoicesContainer>
        );
      })}
    </>
  );
}

const ChoicesContainer = styled.div`
  position: relative;
  padding: 20px 15px;
  border-radius: 5px;
  border: ${(props) =>
    props.isSelected == 'selected'
      ? `1px solid ${blue.bright}`
      : `1px solid ${grayscale.lightGray}`};
  box-shadow: ${(props) =>
    props.isSelected == 'selected' &&
    `0px 0px 10px 1px rgba(88, 171, 255, 0.75)`};
  background-color: ${(props) =>
    props.isSelected == 'selected' ? `#f1f1f1` : `#f8f8f8`};
  margin-bottom: 8px;
  cursor: ${(props) => (props.submitted ? `initial` : `pointer`)};
  color: ${(props) => {
    if (props.submitted) {
      if (props.isCorrect) {
        return `${green.dark}`;
      } else {
        return `${red.dark}`;
      }
    } else {
      return `black`;
    }
  }};

  &:hover {
    border: ${(props) =>
      props.isSelected == 'selected'
        ? `1px solid ${blue.bright}`
        : `1px solid ${grayscale.darkDark}`};
    border: ${(props) => {
      if (props.submitted) {
        if (props.isSelected) {
          return `1px solid ${blue.bright}`;
        }
        return `1px solid ${grayscale.lightGray}`;
      }
    }};
  }
`;

const Checkmark = styled.div`
  position: absolute;
  right: 10px;
  top: 0;
  top: 50%;
  transform: translateY(-50%);
`;
