import React, { useState, useEffect } from 'react';

// Components
import Header from '../PreviewComponents/Header/Header';
import Question from '../PreviewComponents/Question/PreviewQuestion';
import Explanation from '../PreviewComponents/Explanation/PreviewExplanation';
import Stem from '../PreviewComponents/Stem/PreviewStem';

// Styles
import styled from 'styled-components';

export default function LiveEncounter(props) {
  const { encounter, answerEncounter, clinicEncounterAnswered } = props;

  // logic = { length: number, set: array, active: object, selectedChoices: array };
  const [logic, setLogic] = useState();

  useEffect(() => {
    const selectedChoices = encounter.encounterlogicSet.map((item) => {
      return {
        questionId: item.question.id,
        selectedChoice: null,
      };
    });
    setLogic({
      length: encounter.encounterlogicSet.length, // the length of the array
      set: encounter.encounterlogicSet, // array of objects
      active: encounter.encounterlogicSet[0], // object
      selectedChoices,
    });
  }, []);

  const [submitted, setSubmitted] = useState();
  useEffect(() => {
    if (clinicEncounterAnswered && logic) {
      setSubmitted(true);
    }
  }, [logic, clinicEncounterAnswered]);

  const questionElement = (
    <Question
      logic={logic}
      setLogic={setLogic}
      submitted={submitted}
      setSubmitted={setSubmitted}
      encounter={encounter}
      answerEncounter={answerEncounter}
      {...props}
    />
  );

  if (!encounter || !logic) return null;

  return (
    <>
      <Header hashId={encounter.hashId} {...props} />
      <EncounterContainer>
        <div className="inner">
          <InnerLogicContainer>
            <div>
              <Stem content={logic.active.document} submitted={submitted} />

              {/* Answer Container - Mobile */}
              <div className="mobile">{questionElement}</div>

              {/* Explanation */}
              <Explanation
                encounter={encounter}
                logic={logic}
                submitted={submitted}
              />
            </div>

            {/* Answer Container - Desktop */}
            <div className="desktop desktop-right">{questionElement}</div>
          </InnerLogicContainer>
        </div>
      </EncounterContainer>
    </>
  );
}

const EncounterContainer = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  min-height: 100%;
  padding: 0px;
  margin: auto;
  background: rgb(242, 242, 242);
  .inner {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 60px;
    padding-bottom: 100px;
    max-width: 1200px;
    margin: auto;
  }

  @media (max-width: 768px) {
    .inner {
      padding-left: 0px;
      padding-right: 0px;
      padding-top: 60px;
    }
  }
`;
const InnerLogicContainer = styled.div`
  display: grid;
  grid-gap: 15px;
  grid-template-columns: 1.8fr 1fr;
  .mobile {
    display: none;
  }
  @media (max-width: 1024px) {
    grid-template-columns: 1.3fr 1fr;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    .desktop {
      display: none;
    }
    .mobile {
      display: block;
    }
  }
`;

// TERMINOLOGY

// DEFINITION - 'selectedChoices'
// An array of objects located within the logic state.
// Each object has { questionId: A number, selectedChoice: Choice object that the user had picked}
// - Used to keep a record of what answer choices the user had selected as they progress through an encounter.
// ===> Used when navigating questions (next/back), to set the 'selectedChoice' state
// - Used to submit answer choices when encounter is submitted.
