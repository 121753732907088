import React, { useState } from 'react';
import { homeUrl } from '../../settings';

// DEPENDENCIES

// COMPONENTS
import { SolidButton } from '@bit/medzcool.cytoplasm.buttons';

// STYLES
import styled from 'styled-components';
import { blue } from '@bit/medzcool.cytoplasm.colors';

// Apollo, GraphQL
import { useMutation } from '@apollo/client';
import { EDIT_ENCOUNTER_HASH } from '../DataStructures/Mutations';

export default function EditIdentity(props) {
  const { encounter, user } = props;

  const [oldHash, setOldHash] = useState(encounter.hashId);
  const [currentHash, setCurrentHash] = useState(encounter.hashId);
  const [hashEdited, setHashEdited] = useState(false);

  const [editEncounterHash, { error }] = useMutation(EDIT_ENCOUNTER_HASH, {
    variables: {
      userId: user.id,
      encounterId: encounter.id,
      newEncounterHash: currentHash,
    },
    onCompleted: (data) => {
      window.location.href =
        homeUrl + `/edit/${data.editEncounterHash.encounter.hashId}`;
    },
    onError: (error) => {
      console.log(error.graphQLErrors[0].message);
    },
  });

  function changeHash(e) {
    const changedHash = e.target.value;
    setCurrentHash(changedHash);
    setHashEdited(true);
  }

  return (
    <EditHashContainer>
      <span style={{ marginRight: 15, display: 'inline-block' }}>
        <h3>Hash ID:</h3>
      </span>

      {error && (
        <div style={{ color: 'red' }}>{error.graphQLErrors[0].message}</div>
      )}

      <input type="text" value={currentHash} onChange={(e) => changeHash(e)} />

      <span style={{ marginLeft: 15 }}>
        {hashEdited && oldHash != currentHash && (
          <SolidButton onClick={editEncounterHash}>Save</SolidButton>
        )}
      </span>
    </EditHashContainer>
  );
}
const EditHashContainer = styled.div`
  ${SolidButton} {
    width: max-content;
  }
`;
