/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const GET_ENCOUNTER = gql`
  query encounter($hashId: String!) {
    encounter(hashId: $hashId) {
      id
      hashId
      encounterType
      title
      description
      thumbnail
      dateCreated
      attention
      isPublic
      clinicEncounter
      incomplete
      author {
        id
        username
      }
      noteSet {
        id
        date
        text
        user {
          id
        }
      }
      tags {
        id
        name
      }
      documentSet {
        id
        title
        body
        metadata
      }
      questionSet {
        id
      }
      summary {
        id
        title
        body
        metadata
      }
      encounterlogicSet {
        id
        order
        document {
          id
          title
          body
          metadata
        }
        question {
          id
          text
          votes
          choiceSet {
            id
            text
            correctAnswer
            votes
          }
        }
        explanation {
          id
          title
          body
          metadata
        }
      }
    }
  }
`;

export const GET_ENCOUNTERS = gql`
  query encounters($offset: Int, $limit: Int, $flags: JSONString) {
    encounters(offset: $offset, limit: $limit, flags: $flags) {
      id
      hashId
      encounterType
      title
      description
      thumbnail
      author {
        id
        username
      }
      attention
      isPublic
      clinicEncounter
      incomplete
      dateCreated
      noteSet {
        id
        date
        text
        user {
          id
        }
      }
      tags {
        id
        name
      }
      documentSet {
        id
        title
        body
        metadata
      }
      questionSet {
        id
      }
      summary {
        id
        title
        body
        metadata
      }
      encounterlogicSet {
        id
        order
        document {
          id
          title
          body
          metadata
        }
        question {
          id
          text
          votes
          choiceSet {
            id
            text
            correctAnswer
            votes
          }
        }
        explanation {
          id
          title
          body
          metadata
        }
      }
    }
  }
`;

export const GET_COLLECTIONS = gql`
  query collections {
    collections {
      id
      collectionUrl
      collectionTitle
      description
      encounterSet {
        id
        hashId
      }
    }
  }
`;

export const GET_COLLECTION = gql`
  query collection($collectionId: Int, $collectionUrl: String) {
    collection(collectionId: $collectionId, collectionUrl: $collectionUrl) {
      id
      collectionUrl
      collectionTitle
      description
      encounterSet {
        id
        hashId
        encounterType
        title
        description
        thumbnail
        dateCreated
        attention
        isPublic
        clinicEncounter
        incomplete
        author {
          id
          username
        }
        tags {
          id
          name
        }
      }
    }
  }
`;