import React from 'react';

// DEPENDENCIES
import { Link } from 'react-router-dom';

// COMPONENTS
import { SolidButton } from '@bit/medzcool.cytoplasm.buttons';

// STYLES
import styled from 'styled-components';
import { grayscale } from '@bit/medzcool.cytoplasm.colors';

export default function Header(props) {
  return (
    <HeaderContainer>
      <HeaderGrid>
        <div className="header-nav" onClick={() => props.history.push('/')}>
          Encounters
        </div>
        <div
          className="header-nav"
          onClick={() => props.history.push('/collections')}
        >
          Collections
        </div>
      </HeaderGrid>
    </HeaderContainer>
  );
}
const HeaderContainer = styled.div`
  position: fixed;
  padding: 0 15px;
  left: 0px;
  top: 0px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05);
  z-index: 98;
  height: 50px;
  display: grid;
  align-content: center;
  border-bottom: 1px solid ${grayscale.lightGray};
  .back-container {
    color: black;
    background: transparent;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    padding-left: 15px;
    padding-right: 20px;
    height: inherit;
    font-size: 18px;
  }
  @media (max-width: 768px) {
    .back-container {
      position: inherit;
      padding-left: 25px;
      left: 0;
    }
  }
`;
const HeaderGrid = styled.div`
  display: grid;
  grid-gap: 50px;
  grid-template-columns: min-content min-content;
  .header-nav {
    cursor: pointer;
  }
`;
