import React, { useState, useEffect } from 'react';

// DEPENDENCIES
import ReadOnly from '@bit/medzcool.cytoplasm.readonly';

// COMPONENTS
import { Block } from '../Block/Block';

// STYLES
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import styled from 'styled-components';

export default function Stem(props) {
  const { content, submitted } = props;

  const [open, setOpen] = useState(true);
  useEffect(() => {
    if (submitted) {
      setOpen(false);
    }
  }, [submitted]);

  function toggleOpen() {
    setOpen(!open);
  }

  return (
    <Block>
      <div className="document-title" style={!open ? { marginBottom: 0 } : {}}>
        <Grid>
          <div>{content.title}</div>
          {submitted && (
            <div className="chevron-down" onClick={toggleOpen}>
              <FontAwesomeIcon icon={open ? faChevronUp : faChevronDown} />
            </div>
          )}
        </Grid>
      </div>
      {open && (
        <div className="serif">
          <ReadOnly content={content.body} />
        </div>
      )}
    </Block>
  );
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr min-content;
  .chevron-down {
    cursor: pointer;
  }
`;
