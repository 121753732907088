import React, { useState, useEffect } from 'react';

// Components
import { SolidButton } from '@bit/medzcool.cytoplasm.buttons';

// Styles
import styled from 'styled-components';

// Apollo, GraphQL
import { useMutation } from '@apollo/client';
import { EDIT_TAGS } from '../DataStructures/Mutations';
import { text } from '@fortawesome/fontawesome-svg-core';

export default function Tags(props) {
  const { encounter } = props;
  const [editTags] = useMutation(EDIT_TAGS);

  const [textValue, setTextValue] = useState();
  const [prevTextValue, setPrevTextValue] = useState();

  useEffect(() => {
    let newArray = encounter.tags.reduce((newArray, tag) => {
      newArray.push(tag.name);
      return newArray;
    }, []);
    setTextValue(newArray.join(', '));
    setPrevTextValue(newArray.join(', '));
  }, []);

  function handleChange(e) {
    setTextValue(e.target.value);
  }

  function getTags() {
    const textValueArr = textValue.split(',');
    let newArray = textValueArr.reduce((newArray, val) => {
      if (!val.length === 0 || val.trim()) {
        const trimVal = val.trim().toLowerCase();
        newArray.push(trimVal);
      }
      return newArray;
    }, []);
    setTextValue(newArray.join(', '));
    editTags({
      variables: {
        encounterId: encounter.id,
        tags: newArray.toString(),
      },
    });
  }
  console.log(textValue);
  return (
    <TagsContainer>
      <h3>Tags</h3>
      <textarea value={textValue} onChange={handleChange} rows="5"></textarea>
      {prevTextValue !== textValue && (
        <div>
          <SolidButton onClick={getTags}>Save Tags</SolidButton>
        </div>
      )}
    </TagsContainer>
  );
}
const TagsContainer = styled.div`
  ${SolidButton} {
    width: max-content;
    margin-top: 15px;
  }
  textarea {
    width: 100%;
    resize: none;
  }
`;
