export const development = false;

export let url;
export let homeUrl;

if (development === true) { 
  url = "http://127.0.0.1:8000";
  homeUrl = "https://localhost:3006"
} else {
  url = "https://api.medzcool.com";
  homeUrl = "https://create.medzcool.com"
}

// HARDCODED URLS
// createEvent.js
