import React, { useState } from 'react';
// DEPENDENCIES
// COMPONENTS
import EncounterCardMini from '../Encounters/EncounterCardMini';
import Header from '../Navigation/Header';

// STYLES
import styled from 'styled-components';
import { grayscale, green, red } from '@bit/medzcool.cytoplasm.colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLayerMinus, faLayerPlus } from '@fortawesome/pro-regular-svg-icons';

// DATA
import { useQuery, useMutation } from '@apollo/client';
import { GET_COLLECTION } from '../DataStructures/Queries';
import { ENCOUNTER_TO_COLLECTION } from '../DataStructures/Mutations';

export default function Collection(props) {
  const { history } = props;

  const [collection, setCollection] = useState();

  const [encounterToCollection] = useMutation(ENCOUNTER_TO_COLLECTION, {
    onCompleted: (data) => {
      setCollection(data.encounterToCollection.collection);
    },
  });
  function removeEncounter(encounter) {
    const confirm = window.confirm(
      'Are you sure you want to remove this encounter from the collection?',
    );
    if (confirm) {
      encounterToCollection({
        variables: {
          encounterId: encounter.id,
          collectionId: collection.id,
          instructions: 'remove',
        },
      });
    }
  }

  function renderIcon(encounter) {
    const encounterInCollection = collection.encounterSet.find(
      (collectionEncounter) => collectionEncounter.id == encounter.id,
    );

    if (encounterInCollection) {
      return (
        <div
          className="collection-icon remove"
          onClick={() => removeEncounter(encounter)}
        >
          <FontAwesomeIcon icon={faLayerMinus} size="lg" />
        </div>
      );
    } else {
      return (
        <div
          className="collection-icon add"
          onClick={() => removeEncounter(encounter)}
        >
          <FontAwesomeIcon icon={faLayerPlus} size="lg" />
        </div>
      );
    }
  }

  const { loading, error, data } = useQuery(GET_COLLECTION, {
    variables: {
      collectionUrl: props.match.params.collectionUrl,
    },
    onCompleted: (data) => {
      setCollection(data.getCollection);
    },
  });
  if (loading || !collection) return null;
  if (error) return <h1>Server Error</h1>;

  return (
    <>
      <Header {...props} />
      <CollectionContainer>
        <div className="metadata">
          <h2 className="title">{collection.collectionTitle}</h2>
          <p>{collection.description}</p>
        </div>
        <div className="encounters">
          {collection.encounterSet.length < 1 && (
            <p
              style={{
                color: 'gray',
                textAlign: 'center',
                padding: '50px 25px',
                fontSize: '0.75em',
                fontStyle: 'italic',
              }}
            >
              This collection is empty
            </p>
          )}
          {collection.encounterSet.map((encounter) => {
            return (
              <EncounterCardContainer key={encounter.id}>
                <EncounterCardMini encounter={encounter} history={history} />
                {renderIcon(encounter)}
              </EncounterCardContainer>
            );
          })}
        </div>
      </CollectionContainer>
    </>
  );
}
const CollectionContainer = styled.div`
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid-template-columns: 2fr 5fr;
  grid-gap: 25px;
  margin-top: 25px;
  .title {
    line-height: 100%;
  }
`;
const EncounterCardContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  grid-gap: 25px;
  .collection-icon {
    cursor: pointer;
    &.add {
      color: ${green.dark};
    }
    &.remove {
      color: ${red.dark};
    }
  }
`;
