import React, { useState, useEffect } from 'react';
// DEPENDENCIES

// COMPONENTS
import Editor from '@bit/medzcool.cytoplasm.editor';
import ReadOnly from '@bit/medzcool.cytoplasm.readonly';
import SectionHeader from './SectionHeader';
import { Block } from '../PreviewComponents/Block/Block';
import { BorderButton } from '@bit/medzcool.cytoplasm.buttons';

// STYLES

// DATA
import { useMutation } from '@apollo/client';
import { EDIT_NOTE, DELETE_NOTE } from '../DataStructures/Mutations';

export default function Note(props) {
  const { note, activeLogic, user, encounter } = props;

  const [editing, setEditing] = useState(false);

  const [noteText, setNoteText] = useState(note.text);
  function exportEditorContent(content) {
    setNoteText(content);
  }
  useEffect(() => {
    setNoteText(note.text);
  }, [activeLogic]);

  const [deleteNote] = useMutation(DELETE_NOTE);
  const [editNote] = useMutation(EDIT_NOTE);
  function saveNote() {
    editNote({
      variables: {
        noteId: note.id,
        noteText: noteText,
      },
    });
  }

  function deleteNoteConfirm() {
    const confirm = window.confirm(
      'Are you sure you want to delete this note?',
    );
    if (confirm) {
      deleteNote({
        variables: {
          noteId: note.id,
          encounterId: encounter.id,
        },
      });
    }
  }

  return (
    <Block>
      <SectionHeader
        save={saveNote}
        editing={editing}
        setEditing={setEditing}
      />

      {editing ? (
        <>
          <div className="serif">
            <Editor
              exportEditorContent={exportEditorContent}
              content={noteText}
              user={user}
            />
          </div>
        </>
      ) : noteText ? (
        <div className="serif">
          <ReadOnly content={noteText} />
        </div>
      ) : null}
      <BorderButton
        style={{ border: `none`, textAlign: `right` }}
        onClick={deleteNoteConfirm}
      >
        Delete
      </BorderButton>
    </Block>
  );
}
