import React from 'react';

// DEPENDENCIES

// COMPONENTS
import { BorderButton } from '@bit/medzcool.cytoplasm.buttons';

// STYLES
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { blue, grayscale } from '@bit/medzcool.cytoplasm.colors';
import { fontWeight, fontFamily } from '@bit/medzcool.cytoplasm.typography';

// Apollo, GraphQL
import { useMutation } from '@apollo/client';
import {
  CREATE_QUESTION,
  CREATE_SEQUENCE,
  CREATE_SUMMARY,
  DELETE_ENCOUNTER,
} from '../DataStructures/Mutations';

export default function EditSidebar(props) {
  const {
    encounter,
    activeLogic,
    setActiveLogic,
    setActiveLogicOrder,
    setEditingView,
    editingView,
    user,
  } = props;

  const [createQuestion] = useMutation(CREATE_QUESTION);
  function newQuestion() {
    const nextLogicOrder = encounter.encounterlogicSet.length;

    const document = encounter.documentSet.find(
      (document) => document.title == 'Clinical Scenario',
    );
    const explanation = encounter.documentSet.find(
      (document) => document.title == 'Explanation',
    );

    createQuestion({
      variables: {
        encounterId: encounter.id,
        logicOrder: nextLogicOrder,
        documentId: document.id,
        explanationId: explanation.id,
      },
    });
  }

  const [createSequence] = useMutation(CREATE_SEQUENCE);
  function newSequence() {
    const nextLogicOrder = encounter.encounterlogicSet.length;

    createSequence({
      variables: {
        encounterId: encounter.id,
        logicOrder: nextLogicOrder,
      },
    });
  }

  // switching between 'main' Question/scenarios views
  function changeActiveLogic(logicOrder) {
    setActiveLogic(encounter.encounterlogicSet[logicOrder]);
    setActiveLogicOrder(logicOrder);
    setEditingView('main');
  }

  // When clicking summary
  const [createSummary] = useMutation(CREATE_SUMMARY, {
    onCompleted(data) {
      setEditingView('summary');
    },
  });
  function summaryClick() {
    // Only create summary if the encounter already doesn't have one
    if (!encounter.summary) {
      createSummary({
        variables: { encounterId: encounter.id },
      });
    } else {
      setEditingView('summary');
    }
  }

  const [deleteEncounter] = useMutation(DELETE_ENCOUNTER, {
    onCompleted(data) {
      props.history.push('/');
    },
  });

  function deleteEncounterConfirm() {
    const confirm = window.confirm(
      'Are you sure you want to delete this encounter?',
    );
    if (confirm) {
      deleteEncounter({
        variables: {
          encounterId: encounter.id,
          userId: user.id,
        },
        update(cache) {
          cache.evict({ id: cache.identify(encounter) });
          cache.gc();
        },
      });
    }
  }

  return (
    <SidebarContainer>
      <div className="left">
        <div className="left-fixed">
          {encounter.encounterType != 'single-question' && (
            <>
              <p>Encounter Logics</p>
              {encounter.encounterlogicSet.map((logic) => {
                return (
                  <SidebarItem
                    activeItem={
                      editingView == 'main' && activeLogic.order == logic.order
                    }
                    key={logic.id}
                    onClick={() => changeActiveLogic(logic.order)}
                  >
                    Question {logic.order + 1}
                  </SidebarItem>
                );
              })}
              <BorderButton
                color="gray"
                border="none"
                style={{ marginTop: 15, textAlign: 'left', padding: 0 }}
                onClick={newQuestion}
              >
                <FontAwesomeIcon icon={faPlus} />
                <span style={{ marginLeft: 5 }}>Add Question</span>
              </BorderButton>

              {encounter.encounterType == 'sequential' && (
                <BorderButton
                  color="gray"
                  border="none"
                  style={{ marginTop: 15, textAlign: 'left', padding: 0 }}
                  onClick={newSequence}
                >
                  <FontAwesomeIcon icon={faPlus} />
                  <span style={{ marginLeft: 5 }}>Add Scenario</span>
                </BorderButton>
              )}

              <hr />

              {encounter.encounterType != 'single-question' && (
                <BorderButton
                  color="gray"
                  border="none"
                  style={{ marginTop: 15, textAlign: 'left', padding: 0 }}
                  onClick={summaryClick}
                >
                  <span style={{ marginLeft: 5 }}>
                    <SidebarItem activeItem={editingView == 'summary'}>
                      {encounter.summary ? (
                        'Summary'
                      ) : (
                        <>
                          <FontAwesomeIcon icon={faPlus} />
                          <span style={{ marginLeft: 5 }}>Add Summary</span>
                        </>
                      )}
                    </SidebarItem>
                  </span>
                </BorderButton>
              )}

              <hr />
            </>
          )}
          {encounter.encounterType == 'single-question' && (
            <BorderButton
              color="gray"
              border="none"
              style={{ marginTop: 15, textAlign: 'left', padding: 0 }}
              onClick={() => changeActiveLogic(0)}
            >
              <span>Home</span>
            </BorderButton>
          )}

          <BorderButton
            color="gray"
            border="none"
            style={{ marginTop: 15, textAlign: 'left', padding: 0 }}
          >
            <SidebarItem
              activeItem={editingView == 'notes'}
              onClick={() => setEditingView('notes')}
            >
              Notes
            </SidebarItem>
          </BorderButton>

          <hr />

          <BorderButton
            color="gray"
            border="none"
            style={{ marginTop: 15, textAlign: 'left', padding: 0 }}
          >
            <SidebarItem
              activeItem={editingView == 'metadata'}
              onClick={() => setEditingView('metadata')}
            >
              Metadata
            </SidebarItem>
          </BorderButton>
          <div className="metadata">ID: {encounter.hashId}</div>
          <div className="metadata">Type: {encounter.encounterType}</div>
          <div className="metadata">
            Incomplete: {encounter.incomplete ? 'Yes' : 'No'}
          </div>
          <div className="metadata">
            Requires Attention: {encounter.attention ? 'Yes' : 'No'}
          </div>
          <div className="metadata">
            Public: {encounter.isPublic ? 'Yes' : 'No'}
          </div>
          <div className="metadata">
            Clinic: {encounter.clinicEncounter ? 'Yes' : 'No'}
          </div>

          <hr />

          <BorderButton
            color="red"
            border="none"
            style={{ marginTop: 15, textAlign: 'left', padding: 0 }}
          >
            <SidebarItem onClick={() => deleteEncounterConfirm()}>
              Delete Encounter
            </SidebarItem>
          </BorderButton>
        </div>
      </div>
    </SidebarContainer>
  );
}
const SidebarContainer = styled.div`
  font-family: ${fontFamily.sansSerif};
  .left-fixed {
    position: fixed;
  }
  .metadata {
    font-size: 12px;
    color: gray;
    line-height: 125%;
  }
  hr {
    margin-top: 25px;
    color: ${grayscale.offWhite};
  }
`;
const SidebarItem = styled.div`
  cursor: pointer;
  margin-bottom: 8px;
  color: ${(props) => props.activeItem && `${blue.bright}`};
  font-weight: ${(props) => props.activeItem && `${fontWeight.bold}`};
`;
