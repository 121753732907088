import React from 'react';


// COMPONENTS
import ReadOnly from '@bit/medzcool.cytoplasm.readonly';
import { Block } from '../Block/Block';

// STYLES
export default function PreviewExplanation(props) {
  const { submitted, logic, encounter } = props;

  return (
    <>
      {submitted && encounter.summary && logic.active.order == 0 && (
        <Block>
          <div className="document-title">{encounter.summary.title}</div>
          <div className="serif">
            <ReadOnly content={encounter.summary.body} />
          </div>
        </Block>
      )}

      {submitted && (
        <Block>
          <div className="document-title">{logic.active.explanation.title}</div>
          <div className="serif">
            <ReadOnly content={logic.active.explanation.body} />
          </div>
        </Block>
      )}

      {submitted && encounter.summary && logic.active.order != 0 && (
        <Block>
          <div className="document-title">{encounter.summary.title}</div>
          <div className="serif">
            <ReadOnly content={encounter.summary.body} />
          </div>
        </Block>
      )}
    </>
  );
}
