import React, { useState } from 'react';
// DEPENDENCIES
// COMPONENTS
import Modal from '../../Modal/Modal';
import { Block } from '../../Encounters/PreviewComponents/Block/Block';

// STYLES
import styled from 'styled-components';
import { grayscale, green, red } from '@bit/medzcool.cytoplasm.colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLayerMinus, faLayerPlus } from '@fortawesome/pro-regular-svg-icons';

// DATA
import { useQuery, useMutation } from '@apollo/client';
import { GET_COLLECTIONS } from '../../DataStructures/Queries';
import { ENCOUNTER_TO_COLLECTION } from '../../DataStructures/Mutations';

export default function CollectionModal(props) {
  const { openState, setOpenState, encounter } = props;

  const [encounterToCollection] = useMutation(ENCOUNTER_TO_COLLECTION);
  function handleEncounterToCollection(collection) {
    const encounterInCollection = collection.encounterSet.find(
      (collectionEncounter) => collectionEncounter.id == encounter.id,
    );

    let instructions;
    if (encounterInCollection) {
      instructions = 'remove';
    } else {
      instructions = 'add';
    }

    encounterToCollection({
      variables: {
        encounterId: encounter.id,
        collectionId: collection.id,
        instructions: instructions,
      },
    });
  }

  function renderIcon(collection) {
    const encounterInCollection = collection.encounterSet.find(
      (collectionEncounter) => collectionEncounter.id == encounter.id,
    );

    if (encounterInCollection) {
      return (
        <div className="collection-icon remove">
          <FontAwesomeIcon icon={faLayerMinus} size="lg" />
        </div>
      );
    } else {
      return (
        <div className="collection-icon add">
          <FontAwesomeIcon icon={faLayerPlus} size="lg" />
        </div>
      );
    }
  }

  const { loading, error, data } = useQuery(GET_COLLECTIONS);
  if (loading) return null;
  if (error) return <h1>Server Error</h1>;

  return (
    <Modal
      setOpenState={setOpenState}
      openState={openState}
      title="Add to a Collection"
    >
      {data.collections.map((collection) => {
        return (
          <CollectionsContainer
            key={collection.id}
            onClick={() => handleEncounterToCollection(collection)}
          >
            <Block>
              <div className="collection-icon">
                {openState && renderIcon(collection)}
              </div>

              <div>
                <h5 className="collection-title">
                  {collection.collectionTitle}
                </h5>
                <p className="collection-description">
                  {collection.description}
                </p>
              </div>
            </Block>
          </CollectionsContainer>
        );
      })}
    </Modal>
  );
}
const CollectionsContainer = styled.div`
  .collection-icon {
    &.add {
      color: ${green.dark};
    }
    &.remove {
      color: ${red.dark};
    }
  }
  ${Block} {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    grid-gap: 25px;
    cursor: pointer;
    margin-bottom: 15px;
    border: 1px solid ${grayscale.gray};
    padding: 15px;
    .collection-title {
      margin: 0;
    }
    .collection-description {
      margin: 0;
      font-size: 0.75em;
      line-height: 130%;
    }
  }
`;
