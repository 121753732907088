import React, { useState } from 'react';
// DEPENDENCIES
// COMPONENTS
import { SolidButton } from '@bit/medzcool.cytoplasm.buttons';

// STYLES
import styled from 'styled-components';

// Apollo, GraphQL
import { useMutation } from '@apollo/client';
import { EDIT_TITLE_DESCRIPTION } from '../DataStructures/Mutations';

export default function EditTitleDescription(props) {
  const { encounter } = props;

  const [saved, setSaved] = useState(false);

  const [title, setTitle] = useState(encounter.title || '');
  const [prevTitle, setPrevTitle] = useState(encounter.title || '');

  const [description, setDescription] = useState(encounter.description || '');
  const [prevDescription, setPrevDescription] = useState(
    encounter.description || '',
  );

  const [editTitleDescription] = useMutation(EDIT_TITLE_DESCRIPTION);

  function onChange(e, whichState) {
    if (whichState == 'title') {
      setTitle(e.target.value);
    } else if (whichState == 'description') {
      setDescription(e.target.value);
    }
  }

  function renderSaveBtn() {
    if (!saved) {
      if (prevTitle !== title || prevDescription !== description) {
        return (
          <SolidButton
            style={{ width: 'max-content' }}
            onClick={() => {
              setSaved(true);
              editTitleDescription({
                variables: {
                  title: title,
                  description: description,
                  encounterId: encounter.id,
                },
              });
            }}
          >
            Save
          </SolidButton>
        );
      }
    }
  }

  return (
    <TitleDescriptionContainer>
      <h3>Title</h3>
      <input type="text" value={title} onChange={(e) => onChange(e, 'title')} />

      <h3>Description</h3>
      <textarea
        type="text"
        value={description}
        rows="5"
        onChange={(e) => onChange(e, 'description')}
      />

      {renderSaveBtn()}
    </TitleDescriptionContainer>
  );
}
const TitleDescriptionContainer = styled.div`
  input {
    width: 100%;
  }
  textarea {
    resize: none;
    width: 100%;
  }
`;
