/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const CREATE_ENCOUNTER = gql`
  mutation CreateEncounter($encounterType: String!, $userId: Int!) {
    createEncounter(encounterType: $encounterType, userId: $userId) {
      encounter {
        id
        hashId
      }
      document {
        id
      }
      question {
        id
      }
      explanation {
        id
      }
      encounterLogic {
        id
      }
    }
  }
`;

export const DELETE_ENCOUNTER = gql`
  mutation DeleteEncounter($encounterId: Int!, $userId: Int!) {
    deleteEncounter(encounterId: $encounterId, userId: $userId) {
      encounter {
        id
      }
    }
  }
`;

export const EDIT_THUMBNAIL = gql`
  mutation EditThumbnail(
    $encounterId: Int!
    $imageData: String
    $filename: String!
    $fileType: String!
  ) {
    editThumbnail(
      encounterId: $encounterId
      imageData: $imageData
      filename: $filename
      fileType: $fileType
    ) {
      encounter {
        id
        thumbnail
      }
    }
  }
`;

export const EDIT_TITLE_DESCRIPTION = gql`
  mutation EditTitleDescription(
    $encounterId: Int!
    $title: String
    $description: String!
  ) {
    editTitleDescription(
      encounterId: $encounterId
      title: $title
      description: $description
    ) {
      encounter {
        id
        title
        description
      }
    }
  }
`;

export const EDIT_ENCOUNTER_HASH = gql`
  mutation EditEncounterHash(
    $encounterId: Int!
    $userId: Int!
    $newEncounterHash: String!
  ) {
    editEncounterHash(
      encounterId: $encounterId
      userId: $userId
      newEncounterHash: $newEncounterHash
    ) {
      encounter {
        id
        hashId
      }
    }
  }
`;

export const CREATE_SUMMARY = gql`
  mutation CreateSummary($encounterId: Int!) {
    createSummary(encounterId: $encounterId) {
      encounter {
        id
        hashId
        summary {
          id
          title
          body
          metadata
        }
      }
    }
  }
`;

export const EDIT_FLAG = gql`
  mutation EditFlag($encounterId: Int!, $flagValue: Boolean!, $flag: String!) {
    editFlag(encounterId: $encounterId, flagValue: $flagValue, flag: $flag) {
      encounter {
        id
        attention
        isPublic
        clinicEncounter
        incomplete
      }
    }
  }
`;

export const ANSWER_ENCOUNTER = gql`
  mutation AnswerEncounter(
    $selectedChoices: JSONString
    $userId: Int
    $encounterId: Int!
    $eventLocation: String
  ) {
    answerEncounter(
      selectedChoices: $selectedChoices
      userId: $userId
      encounterId: $encounterId
      eventLocation: $eventLocation
    ) {
      answer
      encounter {
        id
        hashId
        title
        description
        thumbnail
        documentSet {
          id
          title
          body
          metadata
        }
        encounterlogicSet {
          id
          question {
            id
            text
            votes
            choiceSet {
              id
              text
              correctAnswer
              votes
            }
          }
          explanation {
            id
          }
        }
      }
    }
  }
`;

export const CREATE_QUESTION = gql`
  mutation CreateQuestion(
    $encounterId: Int!
    $documentId: Int!
    $explanationId: Int!
    $logicOrder: Int!
  ) {
    createQuestion(
      encounterId: $encounterId
      logicOrder: $logicOrder
      explanationId: $explanationId
      documentId: $documentId
    ) {
      question {
        id
        text
      }
      encounter {
        id
        hashId
        title
        description
        thumbnail
        documentSet {
          id
          title
          body
          metadata
        }
        encounterlogicSet {
          id
          order
        }
      }
    }
  }
`;

export const CREATE_SEQUENCE = gql`
  mutation CreateSequence($encounterId: Int!, $logicOrder: Int!) {
    createSequence(encounterId: $encounterId, logicOrder: $logicOrder) {
      encounter {
        id
        hashId
        title
        description
        thumbnail
        documentSet {
          id
          title
          body
          metadata
        }
        encounterlogicSet {
          id
          order
          document {
            id
            title
            body
            metadata
          }
          question {
            id
            text
            votes
            choiceSet {
              id
              text
              correctAnswer
              votes
            }
          }
          explanation {
            id
            title
            body
            metadata
          }
        }
      }
    }
  }
`;

export const EDIT_QUESTION = gql`
  mutation EditQuestion($id: Int!, $text: String!, $choiceSet: JSONString) {
    editQuestion(id: $id, text: $text, choiceSet: $choiceSet) {
      question {
        id
        text
        choiceSet {
          id
          text
          correctAnswer
        }
      }
    }
  }
`;

export const CREATE_CHOICE = gql`
  mutation createChoice(
    $questionId: Int!
    $text: String
    $correctAnswer: Boolean
  ) {
    createChoice(
      questionId: $questionId
      text: $text
      correctAnswer: $correctAnswer
    ) {
      question {
        id
        choiceSet {
          id
          text
          correctAnswer
        }
      }
    }
  }
`;

export const DELETE_CHOICE = gql`
  mutation DeleteChoice($questionId: Int!, $choiceId: Int!) {
    deleteChoice(questionId: $questionId, choiceId: $choiceId) {
      question {
        id
        choiceSet {
          id
          text
          correctAnswer
        }
      }
    }
  }
`;

export const CREATE_NOTE = gql`
  mutation CreateNote($encounterId: Int!, $userId: Int!) {
    createNote(encounterId: $encounterId, userId: $userId) {
      encounter {
        id
        noteSet {
          id
          date
          text
          user {
            id
          }
        }
      }
    }
  }
`;

export const DELETE_NOTE = gql`
  mutation DeleteNote($encounterId: Int!, $noteId: Int!) {
    deleteNote(encounterId: $encounterId, noteId: $noteId) {
      encounter {
        id
        noteSet {
          id
          date
          text
          user {
            id
          }
        }
      }
    }
  }
`;

export const EDIT_NOTE = gql`
  mutation EditNote($noteId: Int!, $noteText: String) {
    editNote(noteId: $noteId, noteText: $noteText) {
      note {
        id
        date
        text
        user {
          id
        }
      }
    }
  }
`;

export const EDIT_TAGS = gql`
  mutation EditTags($encounterId: Int!, $tags: String!) {
    editTags(encounterId: $encounterId, tags: $tags) {
      encounter {
        id
        tags {
          id
          name
        }
      }
    }
  }
`;

export const CREATE_EVENT = gql`
  mutation CreateEvent(
    $userId: Int
    $location: String
    $category: String
    $action: String
    $label: String
    $description: String
  ) {
    createEvent(
      userId: $userId
      location: $location
      category: $category
      action: $action
      label: $label
      description: $description
    ) {
      event {
        id
      }
    }
  }
`;
